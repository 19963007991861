import React, { useState, useEffect } from 'react';
import '../styles/InstallPrompt.css'; // Import the CSS file

const InstallPrompt = () => {
  const [isIos, setIsIos] = useState(false);
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    setIsIos(isIOS);

    const isStandalone = window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
    setIsInStandaloneMode(isStandalone);
  }, []);

  if (isIos && !isInStandaloneMode) {
    return (
      <div className="install-prompt">
        <b>recommended:</b>
        <br />
        <br />
        To install this app on your iPhone/iPad, press the "Share" button and then "Add to Home Screen".
        <br />
        <br />
        OR ...
        <br />
        <br />
        continue using this app in your browser.
      </div>
    );
  }

  return null;
};

export default InstallPrompt;