// EnhancedStars.js
import React, { useRef, useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Stars } from '@react-three/drei';
import * as THREE from 'three';

const EnhancedStars = () => {
  const { scene } = useThree();
  const starsRef = useRef();

  useEffect(() => {
    // Create a gradient background
    const gradientTexture = new THREE.CanvasTexture(generateGradient());
    gradientTexture.minFilter = THREE.LinearFilter; // Important for scaling the texture
    gradientTexture.magFilter = THREE.LinearFilter;
    scene.background = gradientTexture;
  }, [scene]);

  // Function to generate a canvas-based gradient texture
  const generateGradient = () => {
    const canvas = document.createElement('canvas');
    canvas.width = 512;
    canvas.height = 512;
    const context = canvas.getContext('2d');

    const gradient = context.createLinearGradient(0, 0, 0, canvas.height);
    gradient.addColorStop(0, '#02010a'); // Dark blue
    gradient.addColorStop(0.2, '#02010a'); // Black
    gradient.addColorStop(0.8, '#02010a'); // Black
    gradient.addColorStop(1, '#02010a'); // Black

    context.fillStyle = gradient;
    context.fillRect(0, 0, canvas.width, canvas.height);

    return canvas;
  };

  return (
    <>
      <Stars
        ref={starsRef}
        radius={80000 / 2}
        depth={3}
        count={50000}
        factor={3}
        saturation={10}
        fade
        speed={1}
      />
      <Stars
        radius={80000 / 1.5}
        depth={4}
        count={80000}
        factor={8}
        saturation={50}
        fade
        speed={1}
      />
      <Stars
        radius={80000 / 1.2}
        depth={5}
        count={50000}
        factor={3}
        saturation={100}
        fade
        speed={1}
      />
      <Stars
        radius={80000}
        depth={50}
        count={50000}
        factor={3}
        saturation={100}
        fade
        speed={1}
      />
    </>
  );
};

export default EnhancedStars;
