// SpaceshipControls.js
import React from 'react';
import { FaRocket, FaArrowLeft } from 'react-icons/fa';
import './SpaceshipControls.css';

const SpaceshipControls = ({ 
  isSpaceshipView, 
  toggleSpaceshipView, 
  movementSpeed, 
  rotationSpeed,
  setMovementSpeed,
  setRotationSpeed,
  isMobile
}) => {
  const handleBlur = (e) => {
    e.target.blur();
  };



  return (
  <div className="spaceship-controls-container">
      {!isMobile && (
        <>
          <button 
            onClick={toggleSpaceshipView}
            className="spaceship-toggle-btn"
          >
            {isSpaceshipView ? (
              <>
                <FaArrowLeft /> Exit Spaceship
              </>
            ) : (
              <>
                <FaRocket /> Enter Spaceship
              </>
            )}
          </button>
        </>
      )}

      {isSpaceshipView && (
        <div className="spaceship-controls-info">
          {!isMobile && (
            <>
              <h3>Spaceship Controls</h3>
              {window.innerWidth > 768 && (
                <ul>
                  <li><strong>W, A, S, D:</strong> Move</li>
                  <li><strong>Space / Shift:</strong> Up / Down</li>
                  <li><strong>Arrow Keys:</strong> Rotate</li>
                  <li><strong>F Key:</strong> Shoot Laser</li>
                </ul>
              )}
            </>
          )}
          
          <div className="spaceship-stats">
            <label>
              Speed: {movementSpeed.toFixed(2)}
              <input 
                type="range" 
                min="5" 
                max="220" 
                step="1" 
                value={movementSpeed}
                onChange={(e) => setMovementSpeed(Number(e.target.value))}
                onMouseUp={handleBlur}
              />
            </label>
            <label>
              Rotation: {rotationSpeed.toFixed(4)}
              <input 
                type="range" 
                min="0.0005" 
                max="0.05" 
                step="0.0005" 
                value={rotationSpeed}
                onChange={(e) => setRotationSpeed(Number(e.target.value))}
                onMouseUp={handleBlur}
              />
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpaceshipControls;