import { Howl } from 'howler';
import space_ship_sound from '../sounds/space_ship.wav';
import lazer from '../sounds/lazer.wav'
import open_dash_sound from '../sounds/open_dash.wav';
import throttle_sound from '../sounds/DesertEarthquake_Hit.wav';
import joystick_sound from '../sounds/driver.wav';

const play_joystick = () => {
    const joystick = new Howl({
        src: [joystick_sound], 
        loop: false,
        volume: 0.2,
    });
    console.log("Playing joystick sound");
    joystick.play();
    }

const play_throttle = () => {
    const throttle = new Howl({
      src: [throttle_sound ], 
      loop: true,
      volume: 0.01,
    });
    console.log("Playing throttle sound");
    throttle.play();
  };


const play_egnition = () => {
  const egnition = new Howl({
    src: [space_ship_sound ], 
    loop: false,
    volume: 0.2,
  });
  console.log("Playing egnition sound");
  egnition.play();
};

const play_lazer = () => {
  const lazer_sound = new Howl({
    src: [lazer],
    loop: false,
    volume: 0.2,
  });
  console.log("Playing lazer sound");
  lazer_sound.play();
};

const play_open_dash = () => {
    const open_dash = new Howl({
        src: [open_dash_sound],
        loop: false,
        volume: 0.2,
    });
    console.log("Playing open dash sound");
    open_dash.play();
    }



export { play_egnition ,play_lazer ,play_open_dash ,play_throttle,play_joystick};