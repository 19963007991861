import React, { useEffect, useRef } from 'react';
import Sun from './planets/Sun';
import OrbitLine from './planets/OrbitLine';
import Planet from './planets/Planet';
import sunTex from '../assets/tex/2k_sun.jpg';
import { planetData } from './planets/planetData';

const SCALE_FACTOR = 100;
const ORBIT_SPEED_FACTOR = 1 / 100;

const getRandomValueInRange = (min, max) => Math.random() * (max - min) + min;

const Planets = ({ handlePlanetClick, solarSystem }) => {
  const sunRef = useRef(null);
  const planetsRef = useRef(null);

  const [ranTexArr, setRanTexArr] = React.useState([]);

  // Function to pick a random planet texture from planetData
  const getRandomPlanetTexture = () => {
    const randomPlanetTex = [];
    for (let i = 0; i < planetData.length; i++) {
      const randomIndex = Math.floor(Math.random() * planetData.length);
      randomPlanetTex.push(planetData[randomIndex].texture);
    }
    setRanTexArr(randomPlanetTex);
  };

  useEffect(() => {
    getRandomPlanetTexture();

    if (solarSystem) {
      if (solarSystem.length > 0) {
        sunRef.current = solarSystem[0].sun;
        planetsRef.current = solarSystem[0].planets;

        let distanceMultiplier = 5.5;
        let sizeMultiplier = 4.05;
        let tempMultiplier = 1.02;
        let tempEccentricity = 0.1;
        let tempInclination = 10.1;

        planetsRef.current.forEach((planet, index) => {

          // Incrementally adjust the values
          planet.SemiMajorAxisAU *= distanceMultiplier; // Increase distance between planets
          planet.RadiusJpt *= sizeMultiplier;           // Slightly vary size
          planet.PeriodDays *= distanceMultiplier;      // Adjust orbital period proportionally
          planet.Eccentricity *= tempEccentricity;      // Slightly vary eccentricity
          planet.Inclination += tempInclination;         // Slightly vary inclination

          // Fallback to random values if Inclination or Obliquity are NaN
          planet.Inclination = isNaN(planet.Inclination)
            ? getRandomValueInRange(0, 10)  // Assign a small random inclination
            : planet.Inclination + index * index; // Vary inclination with index

          planet.Obliquity = isNaN(planet.Obliquity)
            ? getRandomValueInRange(0, 5)   // Assign a small random obliquity
            : planet.Obliquity + index * 2; // Vary obliquity with index

          planet.SurfaceTempK *= tempMultiplier;        // Slightly vary temperature

          // Add a phase angle for each planet
          planet.InitialPhaseAngle = getRandomValueInRange(0, Math.PI * 2);  // Randomize the initial phase angle

          // Increase multipliers for the next planet
          distanceMultiplier += 8.1;
          sizeMultiplier += 3.85;
          tempMultiplier += 0.82;
          tempEccentricity += 0.1;
          tempInclination += 30.1;
        });
      }
    }
  }, [solarSystem]);

  return (
    <>
      {sunRef.current && (
        <Sun position={[0, 0, 0]} size={sunRef.current.RadiusJpt * SCALE_FACTOR * 10} texture={sunTex} />
      )}
      {planetsRef.current &&
        planetsRef.current.map((planet, index) => (
          <React.Fragment key={index}>
            <Planet
              size={planet.RadiusJpt * SCALE_FACTOR * 400}
              texture={ranTexArr[index] || sunTex} // Use random texture
              rotationSpeed={(1 / (planet.PeriodDays || 1)) * 0.01}
              orbitSpeed={(1 / (planet.PeriodDays || 1)) * ORBIT_SPEED_FACTOR * 100}
              distanceFromSun={planet.SemiMajorAxisAU * SCALE_FACTOR / 40}
              name={`Planet ${index + 1}`}
              orbitalInclination={planet.Inclination} // Use the updated inclination value
              obliquityToOrbit={planet.Obliquity}    // Use the updated obliquity value
              hasRings={false} // Update this with the correct value if available
              numberOfMoons={0} // Update this with the correct value if available
              surfaceGravity={0} // Update this with the correct value if available
              meanTemperature={planet.SurfaceTempK}
              orbitalEccentricity={planet.Eccentricity / 2}
              initialPhaseAngle={planet.InitialPhaseAngle} // Pass the initial phase angle
            />
            <OrbitLine
              sunPosition={[0, 0, 0]}
              distance={planet.SemiMajorAxisAU * SCALE_FACTOR / 40}
              eccentricity={planet.Eccentricity / 2}
              inclination={planet.inclination} // Use the updated inclination value
            />
          </React.Fragment>
        ))}
    </>
  );
};

export default Planets;
