import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import { Vector3, Quaternion, Euler } from 'three';
import { useGLTF } from '@react-three/drei';

import spaceshipModel01 from '../assets/tex/spaceship01.glb';


const Spaceship = ({
  initialPosition = [0, 0, 0],
  initialRotation = [0, 0, 0, 1],
  position,
  rotation
}) => {
  const shipRef = useRef();
  const engineGlowRef = useRef();

  const { scene } = useGLTF(spaceshipModel01);



  const shipScale = 2000;
  const engineGlowPosition = [0, -5 * shipScale, -10 * shipScale];




 
  useFrame((state, delta) => {
    if (shipRef.current) {
      shipRef.current.position.y += Math.sin(state.clock.elapsedTime * 2) * 0.05;
    }

    if (engineGlowRef.current) {
      engineGlowRef.current.intensity = 1 + Math.sin(state.clock.elapsedTime * 5) * 0.3;
    }
  });

 

  useEffect(() => {


    if (position) {
    }
    if (rotation) {
      // Flip the rotation by adjusting the quaternion
      const flippedRotation = new Quaternion().fromArray(rotation);
      const flipQuaternion = new Quaternion().setFromEuler(new Euler(Math.PI, 0, Math.PI)); // Flip around X and Z axes
      flippedRotation.multiply(flipQuaternion);
      
    }
  }, [position, rotation]);
  return (
    <group ref={shipRef} position={position} quaternion={rotation}>
      <primitive object={scene} scale={shipScale} />
      <pointLight ref={engineGlowRef} position={engineGlowPosition} color="#00f" intensity={100.5} distance={20000} />
    </group>
  );
};

export default Spaceship;
