import React, { useState, useEffect } from 'react';
import '../styles/NamePicker.css';
import { Link } from 'react-router-dom';
import { FaRocket, FaMobileAlt } from 'react-icons/fa';
import InstallPrompt from './InstallPrompt';
import { play_egnition } from './SoundEffects';

const NamePicker = ({ userName, setUserName, setIsSubmitted, deferredPrompt, handleInstallClick }) => {
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener('resize', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    if (deferredPrompt) {
      console.log("Deferred Prompt is available:", deferredPrompt);
    } else {
      console.log("Deferred Prompt is not available.");
    }
  }, [deferredPrompt]);

  const handleSubmit = (e) => {
    e.preventDefault();
    play_egnition(); // Play the egnition sound
    console.log("Submitted Username:", userName); // Print the username
    console.log("Attempting to play egnition sound"); // Debug log before playing sound
    setIsSubmitted(true); // Set the submission status to true
  };

  return (
    <div className="name-picker-container">
      <form className="name-picker" onSubmit={handleSubmit}>
        <h1>Sky 1.0</h1>
        <label htmlFor="name-input" className="name-label">
          {isPortrait && (
            <div className="portrait-warning">
              <FaMobileAlt /> <b>Please rotate your device to landscape mode for the best experience.</b>
            </div>
          )}
          <hr />
          <InstallPrompt />
          {deferredPrompt && (
            <>
              <b>Press here to download the galactic app</b>
              <br />
              <Link to='/install'>
                <button onClick={handleInstallClick} className="install-button">
                  Blast Off
                </button>
              </Link>
              <hr />
              <b>Or continue in the browser</b>
              <br />
              <br />
            </>
          )}
          Welcome to a Galactic Adventure!
          <br />
          Embark on an epic journey through the stars, commanding your very own spaceship.
          <br />
          Explore distant galaxies, engage in thrilling space battles, and uncover hidden treasures in the vast expanse of the universe.
          <br />
          Whether you're a seasoned pilot or a newcomer to the cosmos, your adventure awaits.
          <br />
          Enter your name to play as a guest
        </label>

        <input
          id="name-input"
          type="text"
          placeholder="Enter Name"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          className="name-input"
        />
        {userName.trim() && (
          <button type="submit" className='spaceship-toggle-btn' onClick={play_egnition}>Let's Rock <FaRocket /></button>
        )}
        <label htmlFor="name-input" className="name-label">
          <b>OR...</b>
          <br /> 
          sign in to become a regular player and save your progress.
          Get ready to launch into the adventure of a lifetime!
        </label>
        <Link to='/login'>Become a permanent astronaut</Link>
      </form>
    </div>
  );
};

export default NamePicker;