import React, { useRef, useMemo } from 'react';
import { useFrame, extend } from '@react-three/fiber';
import { ShaderMaterial } from 'three';
import Spaceship from './Spaceship';

// Create a custom shader material for the particles
const ParticleMaterial = {
  uniforms: {
    uTime: { value: 0 },
  },
  vertexShader: `
    uniform float uTime;
    attribute float size;
    varying vec3 vColor;
    
    void main() {
      vColor = color;
      vec3 transformed = position;
      transformed.z += sin(uTime + position.x * 10.0) * 10.0;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(transformed, 1.0);
      gl_PointSize = size;
    }
  `,
  fragmentShader: `
    varying vec3 vColor;
    
    void main() {
      gl_FragColor = vec4(vColor, 1.0);
    }
  `,
};

extend({ ParticleMaterial: ShaderMaterial });

function RotatingCube({ spaceshipRotation }) {
  const cubeRef = useRef();

  useFrame(() => {
    if (cubeRef.current) {
      cubeRef.current.rotation.copy(spaceshipRotation);
    }
  });

  return (
    <mesh ref={cubeRef}>
      <boxGeometry args={[10, 10, 10]} />
      <meshStandardMaterial color="blue" wireframe={false} metalness={0.6} roughness={0.4} />
      <pointLight position={[20, 20, -100]} intensity={1.5} />
      <pointLight position={[-20, -20, -100]} intensity={1.5} />
    </mesh>
  );
}

function MiniSpaceship({ position, rotation }) {
  const shipRef = useRef();

  useFrame(() => {
    if (shipRef.current) {
      shipRef.current.quaternion.copy(rotation);
    }
  });

  return (
    <mesh ref={shipRef} position={position} scale={0.1}>
      <Spaceship />
      <spotLight position={[10, 10, -100]} intensity={2} angle={0.3} penumbra={0.5} castShadow />
      <ambientLight intensity={1.3} />
    </mesh>
  );
}

function WorldNode({ position, playerCount }) {
  const nodeRef = useRef();

  useFrame(() => {
    if (nodeRef.current) {
      // Animate the node slightly to make it more dynamic
      nodeRef.current.scale.setScalar(1 + Math.sin(playerCount * 0.1) * 0.1);
    }
  });

  return (
    <group position={position}>
      <mesh ref={nodeRef}>
        <sphereGeometry args={[90, 32, 32]} />
        <meshStandardMaterial color="green" emissive="yellow" emissiveIntensity={0.5} />
      </mesh>
    </group>
  );
}

function ParticleSystem() {
  const particlesRef = useRef();

  const particleCount = 1000;
  const particles = useMemo(() => {
    const positions = new Float32Array(particleCount * 3);
    const colors = new Float32Array(particleCount * 3);
    const sizes = new Float32Array(particleCount);

    for (let i = 0; i < particleCount; i++) {
      const x = (Math.random() - 0.5) * 100;
      const y = (Math.random() - 0.5) * 100;
      const z = (Math.random() - 0.5) * 100;

      positions.set([x, y, z], i * 3);
      colors.set([Math.random(), Math.random(), Math.random()], i * 3);
      sizes[i] = Math.random() * 50 + 1;
    }

    return { positions, colors, sizes };
  }, [particleCount]);

  useFrame(({ clock }) => {
    if (particlesRef.current) {
      particlesRef.current.material.uniforms.uTime.value = clock.getElapsedTime();
    }
  });

  return (
    <points ref={particlesRef}>
      <bufferGeometry>
        <bufferAttribute attachObject={['attributes', 'position']} array={particles.positions} count={particleCount} itemSize={3} />
        <bufferAttribute attachObject={['attributes', 'color']} array={particles.colors} count={particleCount} itemSize={3} />
        <bufferAttribute attachObject={['attributes', 'size']} array={particles.sizes} count={particleCount} itemSize={1} />
      </bufferGeometry>
      <shaderMaterial
        attach="material"
        args={[ParticleMaterial]}
        vertexColors
        transparent
        depthWrite={false}
      />
    </points>
  );
}

export default function WorldMap({ spaceshipRotation, currentWorldData }) {
  return (
    <group>
      <RotatingCube spaceshipRotation={spaceshipRotation} />
      <MiniSpaceship position={[0, -0, -100]} rotation={spaceshipRotation} />
      <ParticleSystem />
      {currentWorldData.adjacentWorlds.map((world, index) => (
        <WorldNode 
          key={index} 
          position={world.position.map(coord => coord * 5)} 
          playerCount={world.playerCount} 
        />
      ))}
    </group>
  );
}
