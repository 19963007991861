// JoystickControls.js
import React from 'react';
import { Joystick } from 'react-joystick-component';
import '../styles/JoystickControls.css';

const JoystickControls = ({ onMove, onStop }) => {
  return (
    <div className="joystick-controls">
      <Joystick
        size={100}
        baseColor="rgba(255, 255, 255, 0.5)" // White color with 50% transparency
        stickColor="rgba(0, 0, 0, 0.5)" 
        stickSize={40}
        move={onMove}
        stop={onStop}
        tabIndex="-1" // Prevent focus
        style={{ userSelect: 'none', outline: 'none' }} // Prevent text selection and focus outline

      />
    </div>
  );
};

export default JoystickControls;
