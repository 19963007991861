// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from './components/Navigation';
import ErrorBoundary from './components/ErrorBoundary';
import HomePage from './components/HomePage';
import './styles/App.css';
import socket from './services/socket';
import LoginPage from './components/auth';
import Dashboard from './components/Dashboard';

function App() {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [isMobile, setIsMobile] = useState(false);



  useEffect(() => {
    setIsMobile(/Mobi|Android/i.test(navigator.userAgent));

    if (isMobile) {
      console.log("Mobile detected. Setting spaceship view and PvP mode.");

    }
  }, []);
  useEffect(() => {
    const onConnect = () => {
      console.log("Connected to the server");
      setIsConnected(true);
    };

    const onDisconnect = () => {
      console.log("Disconnected from the server");
      setIsConnected(false);
    };

    socket.connect();

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);



    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.disconnect();
    };
  }, []);

  return (
    <Router>
      <div className="App">
      {!isMobile && <Navigation />}
      <ErrorBoundary>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/Login" element={<LoginPage />} />
          </Routes>
        </ErrorBoundary>
        <div className="server_status">
          <p>Server status: {isConnected ? "Connected" : "Disconnected"}</p>
        </div>
      </div>
    </Router>
  );
}

export default App;
