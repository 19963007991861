// MilkyWayShader.js
import React, { useRef } from 'react';
import { extend, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { shaderMaterial } from '@react-three/drei';

// Define the shader material with time and resolution uniforms
const MilkyWayMaterial = shaderMaterial(
  {
    uTime: 0,
    uResolution: new THREE.Vector2(),
    uMouse: new THREE.Vector2()
  },
  // Vertex Shader
  `
  uniform float uTime;
  varying vec2 vUv;
  void main() {
    vUv = uv;
    vec3 transformed = position;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(transformed, 1.0);
  }
  `,
  // Fragment Shader
  `
  uniform float uTime;
  varying vec2 vUv;

  float random(vec2 uv) {
    return fract(sin(dot(uv.xy, vec2(12.9898, 78.233))) * 43758.5453123);
  }

  float noise(vec2 uv) {
    vec2 i = floor(uv);
    vec2 f = fract(uv);
    vec2 u = f * f * (3.0 - 2.0 * f);

    return mix(mix(random(i + vec2(0.0, 0.0)), random(i + vec2(1.0, 0.0)), u.x),
               mix(random(i + vec2(0.0, 1.0)), random(i + vec2(1.0, 1.0)), u.x), u.y);
  }

  void main() {
    vec2 uv = vUv * 10.0;
    float starField = noise(uv + uTime * 0.1) * 0.5 + noise(uv * 0.5 + uTime * 0.1) * 0.5;
    float brightness = pow(starField, 5.0);

    vec3 color = vec3(0.1, 0.2, 0.5) * brightness;
    gl_FragColor = vec4(color, 1.0);
  }
  `
);

extend({ MilkyWayMaterial });

const MilkyWay = () => {
  const meshRef = useRef();
  const materialRef = useRef();

  useFrame((state) => {
    materialRef.current.uTime = state.clock.getElapsedTime();
  });

  return (
    <mesh ref={meshRef} position={[0, 0, -100000]} rotation={[0, 0, 0]}>
      {/* Using circleGeometry for a circular background */}
      <circleGeometry args={[10000, 32]} />
      <milkyWayMaterial ref={materialRef} />
    </mesh>
  );
};

export default MilkyWay;
