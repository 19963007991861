import React from 'react';

const WALL_SIZE = 160000;

const Walls = ({ SYSTEM_RADIUS }) => (
  <group>
    <mesh position={[0, 0, SYSTEM_RADIUS]}>
      <boxGeometry args={[WALL_SIZE, WALL_SIZE, 1]} />
      <meshBasicMaterial color={"white"} opacity={0} transparent={true} />
    </mesh>
    <mesh position={[0, 0, -SYSTEM_RADIUS]}>
      <boxGeometry args={[WALL_SIZE, WALL_SIZE, 1]} />
      <meshBasicMaterial color={"white"} opacity={0} transparent={true} />
    </mesh>
    <mesh position={[-SYSTEM_RADIUS, 0, 0]}>
      <boxGeometry args={[1, WALL_SIZE, WALL_SIZE]} />
      <meshBasicMaterial color={"white"} opacity={0} transparent={true} />
    </mesh>
    <mesh position={[SYSTEM_RADIUS, 0, 0]}>
      <boxGeometry args={[1, WALL_SIZE, WALL_SIZE]} />
      <meshBasicMaterial color={"white"} opacity={0} transparent={true} />
    </mesh>
    <mesh position={[0, SYSTEM_RADIUS, 0]}>
      <boxGeometry args={[WALL_SIZE, 1, WALL_SIZE]} />
      <meshBasicMaterial color={"white"} opacity={0} transparent={true} />
    </mesh>
    <mesh position={[0, -SYSTEM_RADIUS, 0]}>
      <boxGeometry args={[WALL_SIZE, 1, WALL_SIZE]} />
      <meshBasicMaterial color={"white"} opacity={0} transparent={true} />
    </mesh>
  </group>
);

export default Walls;
