import React, { useEffect } from 'react';
import '../styles/Dashboard.css';
import Leaderboard from './Leaderboard';
import NamePicker from './NamePicker';
import socket from '../services/socket';
import SpaceshipControls from './SpaceshipControls';


const Dashboard = ({
  isOpen, 
  toggleDashboard, 
  userName, 
  setUserName, 
  connectedUsers, 
  leaderboard, 
  isSpaceshipView, 
  toggleSpaceshipView, 
  movementSpeed, 
  rotationSpeed, 
  setMovementSpeed, 
  setRotationSpeed,
  isMobile,
  setIsSubmitted,
  isSubmitted,
  deferredPrompt,
  handleInstallClick,
  currentWorld
}) => {


//this useEffect will check if the dashboard is open and if the user is not on mobile, then it will close the dashboard
  useEffect(() => {
    if (!isMobile && isSubmitted) {
      toggleDashboard();
      setIsSubmitted(false); // Reset isSubmitted to false after closing the dashboard
    }
  }, [isMobile, isSubmitted, toggleDashboard, setIsSubmitted]);

  return (
    <div className={`dashboard ${isOpen ? 'open' : ''}`}>
      <div className="dashboard-content">
      <br/>
        {/* if is not mobile should not present name picker at the dashboard */}
        { !isMobile && 
        !setIsSubmitted &&         
        <NamePicker
            userName={userName}
            setUserName={setUserName}
            setIsSubmitted={setIsSubmitted}
            deferredPrompt={deferredPrompt} // Pass the deferredPrompt to Dashboard
            handleInstallClick={handleInstallClick} // Pass the handleInstallClick to Dashboard
          />}
          {/* if is mobile should present the current world */}
            <h1>{currentWorld}</h1>
        <div className="connected-users">
          <p>Connected users: {connectedUsers}</p>
        </div>
        <Leaderboard leaderboard={leaderboard} />
        <div className="server-status">
          <p>Server status: {socket.connected ? "Connected" : "Disconnected"}</p>
        </div>
        <SpaceshipControls 
          isSpaceshipView={isSpaceshipView}
          toggleSpaceshipView={toggleSpaceshipView}
          movementSpeed={movementSpeed}
          rotationSpeed={rotationSpeed}
          setMovementSpeed={setMovementSpeed}
          setRotationSpeed={setRotationSpeed}
          isMobile={isMobile}
        />
      </div>
    </div>
  );
};

export default Dashboard;