import React, { useRef, useCallback, useMemo, useEffect, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { Sphere, Ring } from '@react-three/drei';
import * as THREE from 'three';

const Planet = ({
  size,
  texture,
  rotationSpeed,
  name,
  onClick,
  orbitSpeed,
  distanceFromSun,
  orbitalInclination,
  obliquityToOrbit,
  hasRings,
  numberOfMoons,
  surfaceGravity,
  meanTemperature,
  orbitalEccentricity,
  initialPhaseAngle
}) => {
  const groupRef = useRef();
  const planetRef = useRef();
  const [textureLoaded, setTextureLoaded] = useState(false);
  const [texMap, setTexMap] = useState(null);

  orbitSpeed = orbitSpeed * 1000;

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(name);
    }
  }, [onClick, name]);

  // Load texture in useEffect to ensure it only happens after the component is mounted
  useEffect(() => {
    const loader = new THREE.TextureLoader();
    loader.load(texture, (loadedTexture) => {
      setTexMap(loadedTexture);
      setTextureLoaded(true);
    });
  }, [texture]);

  // Create moons
  const moons = useMemo(() => {
    return Array(numberOfMoons).fill().map((_, index) => {
      const moonSize = size * 0.1;
      const moonDistance = size * 2 + index * moonSize * 3;
      const moonPosition = new THREE.Vector3(moonDistance, 0, 0);
      return { size: moonSize, position: moonPosition };
    });
  }, [numberOfMoons, size]);

  useFrame(({ clock }) => {
    if (groupRef.current && planetRef.current) {
      // Planet rotation
      planetRef.current.rotation.y += rotationSpeed;

      // Orbital rotation
      const time = clock.getElapsedTime();
      const angle = time * orbitSpeed + initialPhaseAngle;

      // Calculate position using orbital characteristics
      const a = distanceFromSun; // semi-major axis
      const e = orbitalEccentricity;
      const b = a * Math.sqrt(1 - e * e); // semi-minor axis

      // Calculate the planet's position in its orbit
      const x = a * Math.cos(angle);
      const z = b * Math.sin(angle);

      // Apply the orbital inclination
      const rotationMatrix = new THREE.Matrix4().makeRotationX((orbitalInclination * Math.PI) / 180);
      const position = new THREE.Vector3(x, 0, z).applyMatrix4(rotationMatrix);

      groupRef.current.position.set(position.x, position.y, position.z);
    }
  });

  // Calculate color based on temperature
  const temperatureColor = useMemo(() => {
    const tempNormalized = (meanTemperature + 273.15) / 500; // Normalize temperature
    return new THREE.Color().setHSL(1 - tempNormalized, 1, 0.5);
  }, [meanTemperature]);

  return (
    <group ref={groupRef}>
      {textureLoaded && (
        <group ref={planetRef} onClick={handleClick}>
          <Sphere args={[size, 64, 64]}>
            <meshStandardMaterial map={texMap} />
          </Sphere>
          {hasRings && (
            <Ring args={[size * 1.5, size * 2, 64]}>
              <meshBasicMaterial opacity={0.5} transparent side={THREE.DoubleSide} />
            </Ring>
          )}
          {moons.map((moon, index) => (
            <Sphere key={index} args={[moon.size, 32, 32]} position={moon.position}>
              <meshStandardMaterial />
            </Sphere>
          ))}
          <pointLight color={temperatureColor} intensity={surfaceGravity * 0.1} distance={size * 10} />
        </group>
      )}
    </group>
  );
};

export default Planet;
