import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import localforage from 'localforage';
import SpaceScene from './SpaceScene';
import Radar from './Radar';
import '../styles/HomePage.css';
import ErrorBoundary from './ErrorBoundary';
import Dashboard from './Dashboard';
import { Vector3, Quaternion } from 'three';
import socket from '../services/socket';
import JoystickControls from './JoystickControls';
import NamePicker from './NamePicker';
import WorldMap from './WorldMap'; // Import the WorldMap component
import ExpBar from './ExpBar'; // Import the ExpBar component

// this import is to check if the user is already logged in
import { auth } from '../firebase'; // Import Firebase auth

//icons for closing the dashboard and opening the dashboard
import { TbRocket, TbRocketOff } from "react-icons/tb";

//importing shooting sound
import { play_lazer ,play_open_dash,play_throttle} from './SoundEffects';
import { set } from 'lodash';

const HomePage = () => {
  const [isSpaceshipView, setIsSpaceshipView] = useState(true);
  const [spaceshipPosition, setSpaceshipPosition] = useState(() => new Vector3(0, 1000, 2000));
  const [spaceshipRotation, setSpaceshipRotation] = useState(() => new Quaternion());
  const [movementSpeed, setMovementSpeed] = useState(120);
  const [rotationSpeed, setRotationSpeed] = useState(0.02);
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);
  const [otherPlayers, setOtherPlayers] = useState({});
  const [userName, setUserName] = useState('');
  const [health, setHealth] = useState(100);
  const [hitEffect, setHitEffect] = useState(false);
  const [isPvPMode, setIsPvPMode] = useState(true);
  const [connectedUsers, setConnectedUsers] = useState(0);
  const [leaderboard, setLeaderboard] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [currentWorld, setCurrentWorld] = useState('World 1');
  const [joystickPositionInput, setJoystickPositionInput] = useState({ x: 0, y: 0 });
  const [joystickRotationInput, setJoystickRotationInput] = useState({ x: 0, y: 0 });
  const spaceSceneRef = useRef();
  const [userExp, setUserExp] = useState(0);
  const [userDisplayExp, setDisplayedExp] = useState([]);
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [serverOffset, setServerOffset] = useState(0); // Store server time offset

  const calculateExp = (currentExp) => {
    let level = 1;
    let expForNextLevel = 100;

    while (currentExp >= expForNextLevel) {
      currentExp -= expForNextLevel;
      level++;
      expForNextLevel = 100 * Math.pow(level, 1.2);
    }

    level = Math.floor(level);
    currentExp = Math.floor(currentExp);
    expForNextLevel = Math.floor(expForNextLevel);

    return [level, currentExp, expForNextLevel];
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setFirebaseUser(user);
        setIsSubmitted(true);
        setUserName(user.email);
      } else {
        setIsSubmitted(false);
      }

      console.log("Checking if the user is on a mobile device...");
      console.log("User agent:", firebaseUser);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    play_open_dash(); // Play the open dashboard sound every time the dashboard is toggled
  }, [isDashboardOpen]);

  const toggleDashboard = () => setIsDashboardOpen(!isDashboardOpen);
  const toggleSpaceshipView = useCallback(() => setIsSpaceshipView((prev) => !prev), []);
  const togglePvPMode = () => setIsPvPMode((prev) => !prev);

  useEffect(() => {
    setIsMobile(/Mobi|Android/i.test(navigator.userAgent));

    if (isMobile) {
      console.log("Mobile detected. Setting spaceship view and PvP mode.");
      setIsSpaceshipView(true);
      setIsPvPMode(true);
    }
  }, [isMobile]);

  useEffect(() => {
    socket.on("updatePlayers", (players) => {
      setOtherPlayers(players || {});
      if (players[socket.id]) setUserExp(players[socket.id].experience);
    });

    socket.on("connectedUsers", (count) => {
      setConnectedUsers(count || 0);
    });

    socket.on('leaderboard', (data) => {
      setLeaderboard(data);
    });

    socket.emit('syncTime', Date.now());

    socket.on('syncTimeResponse', ({ serverTime, clientTime }) => {
      const latency = (Date.now() - clientTime) / 2;
      setServerOffset(serverTime + latency - Date.now());
    });

    return () => {
      socket.off("updatePlayers");
      socket.off("connectedUsers");
      socket.off("leaderboard");
      socket.off("syncTimeResponse");
    };
  }, []);

  useEffect(() => {
    if (userName || firebaseUser) {
      socket.emit('playerState', {
        position: [spaceshipPosition.x, spaceshipPosition.y, spaceshipPosition.z],
        rotation: [spaceshipRotation.x, spaceshipRotation.y, spaceshipRotation.z, spaceshipRotation.w],
        name: userName,
        world: currentWorld
      });
    }
  }, [userName, spaceshipPosition, spaceshipRotation, currentWorld, firebaseUser]);

  useEffect(() => {}, [otherPlayers]);
  useEffect(() => {}, [spaceshipPosition, spaceshipRotation]);
  useEffect(() => { console.log("world:", currentWorld); }, [currentWorld]);

  useEffect(() => {
    if (otherPlayers[socket.id]) setUserExp(otherPlayers[socket.id].experience);
    var userLevel = calculateExp(userExp);
    setDisplayedExp(userLevel);
  }, [otherPlayers, userExp]);

  const handleJoystickPositionMove = (event) => {
    setJoystickPositionInput({
      x: event.x,
      y: -event.y, // Invert the Y axis for forward/backward
    });
  };

  const handleJoystickPositionStop = () => {
    setJoystickPositionInput({ x: 0, y: 0 });
  };

  const handleJoystickRotationMove = (event) => {
    setJoystickRotationInput({
      x: -event.x, // Invert the X axis for left/right look
      y: event.y,
    });
  };

  const handleJoystickRotationStop = () => {
    setJoystickRotationInput({ x: 0, y: 0 });
  };

  function handleShoot(event) {
    event.preventDefault();
    console.log('Shoot button pressed');
    play_lazer(); // Play the laser sound

    if (spaceSceneRef.current) {
      console.log("Shooting projectile");
      spaceSceneRef.current.shootProjectile();
    }
  }

  return (
    <div className="home">
      <div className="intro-visual" aria-label="Interactive 3D space scene">
        <ErrorBoundary>
          <Canvas>
            <SpaceScene 
              ref={spaceSceneRef}
              isSpaceshipView={isSpaceshipView}
              spaceshipPosition={spaceshipPosition}
              spaceshipRotation={spaceshipRotation}
              setSpaceshipPosition={setSpaceshipPosition}
              setSpaceshipRotation={setSpaceshipRotation}
              movementSpeed={movementSpeed}
              rotationSpeed={rotationSpeed}
              otherPlayers={otherPlayers}
              setOtherPlayers={setOtherPlayers}
              health={health}
              setHealth={setHealth}
              hitEffect={hitEffect}
              setHitEffect={setHitEffect}
              isPvPMode={isPvPMode}
              setCurrentWorld={setCurrentWorld}
              joystickPositionInput={joystickPositionInput}
              joystickRotationInput={joystickRotationInput}
              serverOffset={serverOffset} // Pass server offset to SpaceScene for consistent timing
            />
          </Canvas>
        </ErrorBoundary>
      </div>

      <ExpBar currentExp={userDisplayExp[1]} requiredExp={userDisplayExp[2]} level={userDisplayExp[0]} /> 

      {!isMobile &&
        <>
          {/* WorldMap positioned on bottom-right corner */}
          {/* Uncomment and implement WorldMap if needed */}
          {/* <div className="world-map-container">
            <Canvas>
              <WorldMap spaceshipRotation={spaceshipRotation} />
            </Canvas>
          </div> */}
        </>
      }

      {isMobile && !isSubmitted && (
        <NamePicker
          userName={userName}
          setUserName={setUserName}
          setIsSubmitted={setIsSubmitted}
          deferredPrompt={deferredPrompt}
          handleInstallClick={handleInstallClick}
        />
      )}

      {(isMobile && !isSubmitted) || (
        <>
          {isDashboardOpen ? (
            <button className="open-dashboard-btn" onClick={toggleDashboard}>
              <TbRocketOff />
            </button>
          ) : (
            <button className="open-dashboard-btn" onClick={toggleDashboard}>
              <TbRocket />
            </button>
          )}
          <Dashboard 
            isOpen={isDashboardOpen} 
            toggleDashboard={toggleDashboard} 
            userName={userName} 
            setUserName={setUserName} 
            connectedUsers={connectedUsers} 
            leaderboard={leaderboard} 
            isSpaceshipView={isSpaceshipView}
            toggleSpaceshipView={toggleSpaceshipView}
            movementSpeed={movementSpeed}
            rotationSpeed={rotationSpeed}
            setMovementSpeed={setMovementSpeed}
            setRotationSpeed={setRotationSpeed}
            isMobile={isMobile}
            setIsSubmitted={setIsSubmitted}
            isSubmitted={isSubmitted}
            deferredPrompt={deferredPrompt}
            handleInstallClick={handleInstallClick}
            currentWorld={currentWorld}
          />
          <Radar
            playerPosition={spaceshipPosition}
            otherPlayers={otherPlayers}
            userName={userName}
            playerRotation={spaceshipRotation}
            isMobile={isMobile}
          />
          {hitEffect && <div className="hit-effect"></div>}
          <div className="crosshair">
            <div className="crosshair-line"></div>
            <div className="crosshair-line"></div>
          </div>
          <div className="health-bar">
            <div className="health-bar-inner" style={{ width: `${health}%` }}></div>
          </div>

          {isMobile && (
            <>
              <div className='bottom-left'>
                <JoystickControls
                  onMove={handleJoystickPositionMove}
                  onStop={handleJoystickPositionStop}
                />
              </div>
              <div className='bottom-right'>
                <JoystickControls
                  onMove={handleJoystickRotationMove}
                  onStop={handleJoystickRotationStop}
                />
              </div>
              <button
                className="shoot-btn-right" 
                onPointerDown={handleShoot} 
                onPointerUp={handleShoot} 
              >
                Pew
              </button>
              <button 
                className="shoot-btn-left" 
                onPointerDown={handleShoot} 
                onPointerUp={handleShoot}   
              >
                Pew
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default HomePage;
