import React, { useRef, useState, useEffect } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import { Vector3, Quaternion } from 'three';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import tex1 from '../assets/spaceship/spaceship-panels1-ao.png';
import tex2 from '../assets/spaceship/spaceship-panels1-height.png';

const Spaceship = ({
  initialPosition = [0, 0, 0],
  initialRotation = [0, 0, 0, 1],
  position,
  rotation
}) => {
  const [spaceshipPosition, setSpaceshipPosition] = useState(new Vector3().fromArray(initialPosition));
  const [spaceshipRotation, setSpaceshipRotation] = useState(new Quaternion().fromArray(initialRotation));
  const shipRef = useRef();
  const engineGlowRef = useRef();

  const bodyTexture = useLoader(TextureLoader, tex1);
  const glassTexture = useLoader(TextureLoader, tex2);

  const shipScale = 20;
  const bodySize = [20 * shipScale, 10 * shipScale, 20 * shipScale];
  const cockpitSize = 5 * shipScale;
  const wingSize = [10 * shipScale, 2 * shipScale, 15 * shipScale];
  const engineSize = [2 * shipScale, 3 * shipScale, 5 * shipScale];
  const engineGlowPosition = [0, -5 * shipScale, -10 * shipScale];
  const wingPosition = 12 * shipScale;

  


  useFrame((state, delta) => {
    if (shipRef.current) {
      shipRef.current.position.y += Math.sin(state.clock.elapsedTime * 2) * 0.05;
    }

    if (engineGlowRef.current) {
      engineGlowRef.current.intensity = 1 + Math.sin(state.clock.elapsedTime * 5) * 0.3;
    }
  });

 

  useEffect(() => {
    
    if (position) {
      setSpaceshipPosition(new Vector3().fromArray(position));
    }
    if (rotation) {
      setSpaceshipRotation(new Quaternion().fromArray(rotation));
    }
  }, [position, rotation]);

  return (
    <group ref={shipRef} position={spaceshipPosition} quaternion={spaceshipRotation}>
      <mesh>
        <boxGeometry args={bodySize} />
        <meshStandardMaterial map={bodyTexture} metalness={0.6} roughness={0.4} />
      </mesh>
      <mesh position={[0, 5 * shipScale, 4 * shipScale]}>
        <sphereGeometry args={[cockpitSize, 32, 32, 0, Math.PI * 2, 0, Math.PI / 2]} />
        <meshPhysicalMaterial map={glassTexture} transparent opacity={0.7} metalness={0.2} roughness={0.1} />
      </mesh>
      <mesh position={[wingPosition, 0, 0]}>
        <boxGeometry args={wingSize} />
        <meshStandardMaterial map={bodyTexture} metalness={0.8} roughness={0.2} />
      </mesh>
      <mesh position={[-wingPosition, 0, 0]}>
        <boxGeometry args={wingSize} />
        <meshStandardMaterial map={bodyTexture} metalness={0.8} roughness={0.2} />
      </mesh>
      <mesh position={[5 * shipScale, -5 * shipScale, -8 * shipScale]}>
        <cylinderGeometry args={engineSize} />
        <meshStandardMaterial map={bodyTexture} />
      </mesh>
      <mesh position={[-5 * shipScale, -5 * shipScale, -8 * shipScale]}>
        <cylinderGeometry args={engineSize} />
        <meshStandardMaterial map={bodyTexture} />
      </mesh>
      <pointLight ref={engineGlowRef} position={engineGlowPosition} color="#00f" intensity={150} distance={20000} />
    
    </group>
  );
};

export default Spaceship;