import React from 'react';
import Spaceship from './Spaceship';

const NPC = ({ position, rotation, npcId }) => {
  return (
    <Spaceship
      position={position}
      rotation={rotation}
      playerId={npcId}
    />
  );
};

export default NPC;
