import React, { useRef } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import { SphereGeometry, MeshBasicMaterial, TextureLoader, Vector3, RingGeometry } from 'three';
import textureImg from '../assets/tex/portal_tex.jpg';  // Replace with your texture path
import {Text} from '@react-three/drei';

const SphereAtWall = ({ position, spaceshipPosition, axis, sign,worldName  }) => {
  const sphereRef = useRef();
  const texture = useLoader(TextureLoader, textureImg);

  const textRef = useRef();


  useFrame(() => {
    const distanceToWall = sign * (80000 - Math.abs(spaceshipPosition[axis]));

    if (distanceToWall < 10000) {  // Activate when within 10000 units of the wall
      const proximity = 1 - distanceToWall / 10000;
      sphereRef.current.rotation.y += proximity * 0.02;  // Increase rotation speed based on proximity
      sphereRef.current.material.opacity = proximity;  // Set opacity based on proximity
      sphereRef.current.scale.setScalar(1 + proximity * 0.5);  // Scale the sphere based on proximity
    } else {
      sphereRef.current.material.opacity = 0;  // Make the sphere invisible when far from the wall
      sphereRef.current.scale.setScalar(1);  // Reset the scale to the original size
    }
  });

  return (
    <group position={position}>
      <mesh ref={sphereRef} >
        <sphereGeometry args={[250, 32, 32]} />
        <meshBasicMaterial map={texture} transparent />
      </mesh>
      <mesh rotation={[0, Math.PI / 2, 0]}>
        <ringGeometry args={[350, 60, 32]} />
        <meshBasicMaterial color="white" side={2} />
      </mesh>
      <Text
        ref={textRef}
        position={[0, 700, 0]}  // Adjust the Y position to be above the sphere
        fontSize={200}
        rotation={[0, Date.now() / 1000, 0]}
        color="white"
        anchorX="center"
        anchorY="middle"
      >
        {worldName}
      </Text>
    </group>
  );
};

const BorderWarningSphere = ({ spaceshipPosition, neighboringWorlds }) => {
    return (
      <>
        {/* X-axis walls */}
        <SphereAtWall position={[80000, 0, 0]} spaceshipPosition={spaceshipPosition} axis="x" sign={-1} worldName={neighboringWorlds.xPositive} />
        <SphereAtWall position={[-80000, 0, 0]} spaceshipPosition={spaceshipPosition} axis="x" sign={-1} worldName={neighboringWorlds.xNegative} />
  
        {/* Y-axis walls */}
        <SphereAtWall position={[0, 80000, 0]} spaceshipPosition={spaceshipPosition} axis="y" sign={-1} worldName={neighboringWorlds.yPositive} />
        <SphereAtWall position={[0, -80000, 0]} spaceshipPosition={spaceshipPosition} axis="y" sign={-1} worldName={neighboringWorlds.yNegative} />
  
        {/* Z-axis walls */}
        <SphereAtWall position={[0, 0, 80000]} spaceshipPosition={spaceshipPosition} axis="z" sign={-1} worldName={neighboringWorlds.zPositive} />
        <SphereAtWall position={[0, 0, -80000]} spaceshipPosition={spaceshipPosition} axis="z" sign={-1} worldName={neighboringWorlds.zNegative} />
      </>
    );
  };
  

export default BorderWarningSphere;
