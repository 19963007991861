import React, { useRef } from 'react';
import { Vector3 } from 'three';
import { useFrame } from '@react-three/fiber';
import { Html } from '@react-three/drei';
import '../styles/Explosions.css';

const Explosions = ({ explosions, setExplosions }) => {
  const explosionRef = useRef();

  useFrame((state, delta) => {
    setExplosions((prev) =>
      prev.filter((explosion) => {
        explosion.ttl -= delta * 1000; // Faster TTL handling for immediate effect
        return explosion.ttl > 0;
      })
    );
  });

  return (
    <>
      {explosions.map((explosion, index) => (
        <Html
          key={index}
          position={new Vector3().fromArray(explosion.position).addScalar(200)}
          center
          zIndexRange={[0, 0]}
        >
          <div className="explosion-effect" style={{ animationDuration: `${Math.max(explosion.ttl / 1000, 0.2)}s` }} />
        </Html>
      ))}
    </>
  );
};

export default Explosions;
