import React from 'react';
import '../styles/ExpBar.css'; // Include your CSS file for styling

const ExpBar = ({ currentExp, requiredExp, level }) => {
  // Calculate the percentage of the EXP bar to fill
  const expPercentage = (currentExp / requiredExp) * 100;

  return (
    <div className="exp-bar-container">
      <div className="exp-bar">
        <div
          className="exp-bar-fill"
          style={{ width: `${expPercentage}%` }}
        ></div>
      </div>
      <div className="exp-bar-text">
        Level {level} - {currentExp} / {requiredExp} EXP
      </div>
    </div>
  );
};

export default ExpBar;
