import React, { useRef, useEffect, useCallback, useState, forwardRef, useImperativeHandle } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Vector3, Quaternion, Matrix4, Euler } from 'three';
import socket from '../services/socket';
import { throttle } from 'lodash';
import CameraControls from './CameraControls';
import SceneLighting from './SceneLighting';
import Planets from './Planets';
import Projectiles from './Projectiles';
import Explosions from './Explosions';
import OtherPlayers from './OtherPlayers';
import EnhancedStars from './EnhancedStars';
import Walls from './Walls';
import MilkyWay from './MilkyWay';
import NPC from './Npc';
import BorderWarningSphere from './BorderWarningSphere';
import { play_lazer } from './SoundEffects';
const SYSTEM_RADIUS = 80000;

const SpaceScene = forwardRef(({
  isSpaceshipView,
  spaceshipPosition,
  spaceshipRotation,
  setSpaceshipPosition,
  setSpaceshipRotation,
  movementSpeed,
  rotationSpeed,
  otherPlayers,
  setOtherPlayers,
  health,
  setHealth,
  hitEffect,
  setHitEffect,
  isPvPMode,
  setCurrentWorld,
  joystickPositionInput,
  joystickRotationInput,
  serverOffset // Use the server offset here
}, ref) => {
  const { camera } = useThree();
  const keysPressed = useRef({});
  const [projectiles, setProjectiles] = useState([]);
  const [explosions, setExplosions] = useState([]);
  const [solarSystem, setSolarSystem] = useState({ sun: null, planets: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [currentWorld, setCurrentWorldState] = useState('World 1');
  const [npcs, setNPCs] = useState({});
  const [edgeOpacity, setEdgeOpacity] = useState(0);
  const [proximity, setProximity] = useState(0);
  const [tempspaceshiprotaion, setTempSpaceshipRotation] = useState(spaceshipRotation);
  const [neighboringWorlds, setNeighboringWorlds] = useState({
    xPositive: '',
    xNegative: '',
    yPositive: '',
    yNegative: '',
    zPositive: '',
    zNegative: ''
  });

  const shootProjectile = useCallback(() => {
    play_lazer();
    const direction = new Vector3(0, 0, -500).applyQuaternion(spaceshipRotation.clone());
    const projectile = {
      position: spaceshipPosition.clone().add(direction.clone().multiplyScalar(5).add(new Vector3(0, 0, 10))),
      direction,
      speed: 1,
      id: Date.now() + serverOffset, // Adjust with server offset
      origin: socket.id
    };
    setProjectiles((prev) => [...prev, projectile]);

    socket.emit('shootProjectile', {
      ...projectile,
      position: projectile.position.toArray(),
      direction: projectile.direction.toArray(),
      origin: socket.id
    });
  }, [spaceshipPosition, spaceshipRotation, serverOffset]);

  useImperativeHandle(ref, () => ({
    shootProjectile
  }));

  useEffect(() => {
    const handleKeyDown = (event) => {
      keysPressed.current[event.code] = true;
      if (isPvPMode && event.code === 'KeyF') {
        shootProjectile();
      }
    };
    const handleKeyUp = (event) => {
      keysPressed.current[event.code] = false;
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [isPvPMode, shootProjectile]);

  useEffect(() => {
    socket.emit('npcRequest', currentWorld);

    const updatePlayers = (players) => setOtherPlayers(players || {});
    const updatePlayerHealth = ({ playerId, health }) => {
      if (socket.id === playerId) {
        setHealth(health);
        if (health > 0) {
          setHitEffect(true);
          setTimeout(() => setHitEffect(false), 200);
        }
      }
    };
    const handleNewProjectile = (projectile) => {
      if (!projectile.position || !projectile.direction) return;
      setProjectiles((prev) => [
        ...prev,
        {
          ...projectile,
          position: new Vector3().fromArray(projectile.position),
          direction: new Vector3().fromArray(projectile.direction),
        },
      ]);
    };
    const handleNewExplosion = (explosion) => {
      if (!explosion.position) return;
      setExplosions((prev) => [...prev, explosion]);
    };
    const handleNewSolarSystem = ({ solarSystemData, entryState, neighboringWorlds }) => {
      setSolarSystem(solarSystemData);
      setCurrentWorldState(solarSystemData[0].name);
      setCurrentWorld(solarSystemData[0].name);
      setSpaceshipPosition(new Vector3().fromArray(entryState.position));
      setSpaceshipRotation(new Quaternion().fromArray(entryState.rotation));
      setTempSpaceshipRotation(spaceshipRotation.clone().multiply(new Quaternion().setFromEuler(new Euler(Math.PI, 0, 0))));
      setNeighboringWorlds(neighboringWorlds);
      setIsLoading(false);

      socket.emit('npcRequest', solarSystemData[0].name);
    };
    const handleSpaceshipState = (state) => {
      setSpaceshipPosition(new Vector3().fromArray(state.position));
      setSpaceshipRotation(new Quaternion().fromArray(state.rotation));
    };
    const handleInitialNPCs = (initialNPCs) => setNPCs(initialNPCs);
    const handleUpdateNPCs = (updatedNPCs) => setNPCs(updatedNPCs);
    const handleNpcDestroyed = (npcId) => setNPCs(prev => {
      const updated = { ...prev };
      delete updated[npcId];
      return updated;
    });
    const handleUpdateNPCHealth = ({ npcId, health }) => {
      setNPCs(prev => {
        const updated = { ...prev };
        if (updated[npcId]) {
          updated[npcId].health = health;
        }
        return updated;
      });
    };

    socket.on("updatePlayers", updatePlayers);
    socket.on("updatePlayerHealth", updatePlayerHealth);
    socket.on("newProjectile", handleNewProjectile);
    socket.on("newExplosion", handleNewExplosion);
    socket.on("newSolarSystem", handleNewSolarSystem);
    socket.on('spaceshipState', handleSpaceshipState);
    socket.on('initialNPCs', handleInitialNPCs);
    socket.on('updateNPCs', handleUpdateNPCs);
    socket.on('npcDestroyed', handleNpcDestroyed);
    socket.on('updateNPCHealth', handleUpdateNPCHealth);

    setIsLoading(true);

    if (currentWorld === 'World 1') {
      socket.emit('generateNewSolarSystem', { coordinates: [0, 0, 0], entryState: { position: [0, 0, 0], rotation: [0, 0, 0, 1] } });
    }

    return () => {
      socket.off("updatePlayers", updatePlayers);
      socket.off("updatePlayerHealth", updatePlayerHealth);
      socket.off("newProjectile", handleNewProjectile);
      socket.off("newExplosion", handleNewExplosion);
      socket.off("newSolarSystem", handleNewSolarSystem);
      socket.off('spaceshipState', handleSpaceshipState);
      socket.off('initialNPCs', handleInitialNPCs);
      socket.off('updateNPCs', handleUpdateNPCs);
      socket.off('npcDestroyed', handleNpcDestroyed);
      socket.off('updateNPCHealth', handleUpdateNPCHealth);
    };
  }, [setOtherPlayers, setSolarSystem, setSpaceshipPosition, setSpaceshipRotation, currentWorld, setCurrentWorld, isPvPMode, setHealth, setHitEffect]);

  const emitPlayerState = useCallback(throttle((newPosition, newRotation) => {
    socket.emit("playerState", {
      position: [newPosition.x, newPosition.y, newPosition.z],
      rotation: [newRotation.x, newRotation.y, newRotation.z, newRotation.w],
      userId: socket.id,
      world: currentWorld
    });
  }, 20), [currentWorld]);

  const handleWorldChange = useCallback(throttle((coordinates) => {
    if (isLoading) return;
    setIsLoading(true);
    const entryState = calculateEntryPosition(spaceshipPosition, spaceshipRotation);
    socket.emit('generateNewSolarSystem', { coordinates, entryState });
  }, 1000), [spaceshipPosition, spaceshipRotation, isLoading]);

  const calculateEntryPosition = (position, rotation) => {
    let entryPosition = new Vector3().copy(position);
    let entryRotation = new Quaternion().copy(rotation);
    const offset = 10;

    if (position.x >= SYSTEM_RADIUS / 2) entryPosition.x = -SYSTEM_RADIUS + offset;
    else if (position.x <= -SYSTEM_RADIUS) entryPosition.x = SYSTEM_RADIUS - offset;

    if (position.y >= SYSTEM_RADIUS / 2) entryPosition.y = -SYSTEM_RADIUS + offset;
    else if (position.y <= -SYSTEM_RADIUS) entryPosition.y = SYSTEM_RADIUS - offset;

    if (position.z >= SYSTEM_RADIUS / 2) entryPosition.z = -SYSTEM_RADIUS + offset;
    else if (position.z <= -SYSTEM_RADIUS) entryPosition.z = SYSTEM_RADIUS - offset;

    return { position: entryPosition, rotation: entryRotation };
  };

  useFrame((state, delta) => {
    if (!isSpaceshipView) return;

    const offset = new Vector3(0, 800, 2000);
    camera.position.copy(spaceshipPosition).add(offset.applyQuaternion(spaceshipRotation));
    camera.quaternion.copy(spaceshipRotation);

    const direction = new Vector3();
    const rotationMatrix = new Matrix4().makeRotationFromQuaternion(spaceshipRotation);

    // Client-Side Prediction
    if (joystickPositionInput.x !== 0 || joystickPositionInput.y !== 0) {
      direction.x = joystickPositionInput.x;
      direction.z = joystickPositionInput.y;
    } else {
      if (keysPressed.current['KeyW']) direction.z -= 1;
      if (keysPressed.current['KeyS']) direction.z += 1;
      if (keysPressed.current['KeyA']) direction.x -= 1;
      if (keysPressed.current['KeyD']) direction.x += 1;
      if (keysPressed.current['Space']) direction.y += 1;
      if (keysPressed.current['ShiftLeft']) direction.y -= 1;
    }

    direction.applyMatrix4(rotationMatrix);
    direction.normalize().multiplyScalar(movementSpeed);

    const newPosition = spaceshipPosition.clone().add(direction.multiplyScalar(delta));

    // Interpolation
    const lerpPosition = spaceshipPosition.clone().lerp(newPosition, 100);
    setSpaceshipPosition(lerpPosition);

    const distanceFromCenter = lerpPosition.length();

    setProximity(1 - Math.max(0, (SYSTEM_RADIUS - distanceFromCenter) / SYSTEM_RADIUS));

    if (distanceFromCenter >= SYSTEM_RADIUS) {
      const normalizedPosition = newPosition.normalize().multiplyScalar(SYSTEM_RADIUS - 10);
      const coordinates = [
        Math.round(normalizedPosition.x / SYSTEM_RADIUS),
        Math.round(normalizedPosition.y / SYSTEM_RADIUS),
        Math.round(normalizedPosition.z / SYSTEM_RADIUS)
      ];
      handleWorldChange(coordinates);
      const entryPosition = calculateEntryPosition(spaceshipPosition, spaceshipRotation);
      setSpaceshipPosition(entryPosition.position);
      setSpaceshipRotation(entryPosition.rotation);
    } else {
      setSpaceshipPosition(lerpPosition);
    }

    const rotationChange = new Quaternion();
    if (joystickRotationInput.x !== 0 || joystickRotationInput.y !== 0) {
      const euler = new Euler(joystickRotationInput.y * rotationSpeed, joystickRotationInput.x * rotationSpeed, 0);
      rotationChange.setFromEuler(euler);
    } else {
      if (keysPressed.current['ArrowLeft']) rotationChange.setFromAxisAngle(new Vector3(0, 1, 0), rotationSpeed);
      if (keysPressed.current['ArrowRight']) rotationChange.setFromAxisAngle(new Vector3(0, 1, 0), -rotationSpeed);
      if (keysPressed.current['ArrowUp']) rotationChange.setFromAxisAngle(new Vector3(1, 0, 0), rotationSpeed);
      if (keysPressed.current['ArrowDown']) rotationChange.setFromAxisAngle(new Vector3(1, 0, 0), -rotationSpeed);
    }

    const newRotation = spaceshipRotation.clone().multiply(rotationChange);
    setSpaceshipRotation(newRotation);
    setTempSpaceshipRotation(spaceshipRotation.clone().multiply(new Quaternion().setFromEuler(new Euler(0, Math.PI, 0))));

    const proximity = Math.max(0, 1 - ((SYSTEM_RADIUS - distanceFromCenter) / 100000));
    setEdgeOpacity(proximity > 0.7 ? 20 * proximity * proximity : -1 + (4 - 2 * proximity) * proximity / 100);

    // Emit the new position to the server with throttling
    emitPlayerState(lerpPosition, newRotation);

    // Update projectile positions with server offset
    setProjectiles((prevProjectiles) =>
      prevProjectiles.map((projectile) => ({
        ...projectile,
        position: projectile.position.add(projectile.direction.clone().multiplyScalar(projectile.speed * delta + serverOffset)),
      }))
    );
  });

  return (
    <>
      <CameraControls isSpaceshipView={isSpaceshipView} />
      <SceneLighting />
      <Planets solarSystem={solarSystem} />
      <EnhancedStars />
      <MilkyWay />
      
      {isSpaceshipView && (
        <mesh position={[0, 0, -1]}>
          <planeGeometry args={[2, 2]} />
          <meshBasicMaterial color="black" transparent opacity={edgeOpacity} />
        </mesh>
        
      )}
      {isSpaceshipView && <Walls SYSTEM_RADIUS={SYSTEM_RADIUS} />}
      <OtherPlayers currentUserId={socket.id} currentUserPosition={spaceshipPosition} currentUserRotaion={tempspaceshiprotaion} otherPlayers={Object.values(otherPlayers).filter(player => player.world === currentWorld )} />
      <Projectiles projectiles={projectiles} setProjectiles={setProjectiles} />
      <Explosions explosions={explosions} setExplosions={setExplosions} />
      {Object.values(npcs).map(npc => (
        <NPC key={npc.id} position={npc.position} rotation={npc.rotation} npcId={npc.id} />
      ))}
      {proximity > 0 && (
        <BorderWarningSphere
          spaceshipPosition={spaceshipPosition}
          neighboringWorlds={neighboringWorlds}
        />
      )}
    </>
  );
});

export default SpaceScene;
